import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServiceRow from '../../components/service-row';
import HunterDouglasAlustraCollectionImage from '../../images/photos/products/hunter-douglas-alustra-collection.png';
import HunterDouglasDuetteImage from '../../images/photos/products/hunter-douglas-duette.png';
import HunterDouglasDesignImage from '../../images/photos/products/hunter-douglas-design.png';
import SubmenuList from '../../components/submenu-list';
import Config from '../../config';

class FeaturedProjects extends Component {
    render() {
        const route = Config.Services.ProductRoutes.FeaturedProjects;
        return (
            <Layout>
                <div className="featured-products-page">
                    <HelmetEx>
                        <div metadata="title">Hunter Dougles Alustra | Privacy Sheers, Shades | Elmwood Park, NJ</div>
                        <div metadata="keywords">hunter dougles,alustra,blinds,shading,shades,bergen county, new jersey,NJ,window,Silhouette, Duette,Screen,honycomb</div>
                        <div metadata="description">
                            Unique fabrics, finishes, and decorative details distinguish 
                            the exclusive Hunter Douglas Alustra® Collection of custom window 
                            shades, shadings, sheers and blinds.
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>FEATURED PRODUCTS</h1>
                                            {/* <h3></h3> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="navigation-section">
                        <div className="container">
                            <div className="row">
                                <SubmenuList activePath={route} />
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="HUNTER DOUGLAS ALUSTRA® COLLECTION" src={HunterDouglasAlustraCollectionImage}>
                                        <p>
                                            Unique fabrics, finishes, and decorative details distinguish the exclusive Hunter Douglas Alustra®
                                            Collection of custom window shades, shadings, sheers and blinds.
                                        </p>
                                        <p className="margin-top-60px">
                                            From luminous sheers to elegant woven textures, this premium
                                            window treatment collection from Hunter Douglas offers a new
                                            standard of style found nowhere else.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="fade-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-center paragraph-title">
                                            <p>The Hunter Douglas Alustra® Collection includes these exclusive product lines:</p>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h4 className="sub-title">
                                                Alustra® Silhouette® Window Shadings
                                            </h4>
                                            <p>
                                                feature the Signature S-Vane™ and offer an
                                                exclusive selection of coordinated sheer fabrics and hardware finishes.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h4 className="sub-title">
                                                Alustra® Luminette® Privacy Sheers
                                            </h4>
                                            <p>
                                                elevate the style of any room with an exclusive
                                                selection of coordinated sheer fabrics and hardware finishes.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h4 className="sub-title">
                                                Alustra® Duette® Honeycomb Shades
                                            </h4>
                                            <p>
                                                offer energy efficiency and distinctive design with
                                                exclusive Architella® and sheer fabric options.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h4 className="sub-title">
                                                Alustra® Vignette® Modern Roman Shades
                                            </h4>
                                            <p>
                                                combine the soft elegance of a drapery with the function of a
                                                shade and include a fabric-covered headrail and bottom rail.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h4 className="sub-title">
                                                Alustra® Woven Textures®
                                            </h4>
                                            <p>
                                                beautifully showcase natural textures and woven patterns and are
                                                an innovative alternative to solar screens and woven wood shades.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h4 className="sub-title">Alustra® Screen Shades</h4>
                                            <p>
                                                are classic designs for the 21st century that pair the functionality of
                                                a solar screen with the design expertise of The Alustra Collection.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow title="HUNTER DOUGLAS DESIGN STUDIO ROMAN™ SHADES" src={HunterDouglasDesignImage}>
                                        <p>
                                            Create your own soft Roman shades with Hunter Douglas Design Studio™.
                                        </p>
                                        <p className="margin-top-60px">
                                            Hunter Douglas Design Studio™ offers a collection of traditional
                                            Roman shades featuring over 300 timeless fabric and color combinations,
                                            coordinating decorative tapes and trims, an edited selection of shade and
                                            valance styles, and superior craftsmanship.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="HUNTER DOUGLAS DUETTE® ARCHITELLA® HONEYCOMB" src={HunterDouglasDuetteImage}>
                                        <p>
                                            There's nothing else in the market like the Duette® Architella®
                                            Honeycomb Shade Collection. These shades have a state-of-the-art, patented,
                                            honeycomb-within-a-honeycomb design offering the industry’s highest level of energy
                                            efficiency. In fact, these shades are so energy efficient they can reduce energy loss
                                            by up to 50%.
                                        </p>
                                    </ServiceRow>
                                    <p>
                                        Duette® Architella® honeycomb shades double nature's most perfect shape, the honeycomb,
                                        to create a revolutionary shade. Three distinct air pockets trap more cold air in the winter
                                        and more heat from the sun in the summer, so your home stays comfortable during any season.
                                    </p>
                                    <p>
                                        Duette® Architella® honeycomb shades come in 88 material and color combinations.
                                        For details visit The Hunter Douglas iMagine™ Design Center. This interactive tool makes
                                        it easy to view your favorite Hunter Douglas products up close and in detail with all
                                        available materials and in every possible color.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default FeaturedProjects;